export const roadmapItems = [
  {
    id: 1,
    date: "2006 - 2008",
    title: " NFT RELEASE ",
    subTitle: "Lorem ipsum",
    description: [
      <li>
        NFTs will be released on multiple networks, giving fan a chance to mint
        a TOLR NFT on their favourite network.
      </li>,
      <li>The releases will be broken down in to phases.</li>,
      <li> 1. Private Sale</li>,
      <li>2. Pre-sale</li>,
      <li>3. Public Sale</li>,
      <li>3. After Sale</li>,
      <li>
        The phased sale mechanism was put in place to avoid gas wars and high
        fees to the minters.
      </li>,
    ],
  },
  {
    id: 2,
    date: "2006 - 2008",
    title: " TOLR UTILITY TOKEN AIRDROP ",
    subTitle: "Lorem ipsum",
    description: [
      <li>
        Each holder of a TOLR NFT will be eligible to participate in the TOLR
        token airdrop.
      </li>,
      <li>
        The TOLR token will be the mode of transaction and utility within the
        TOLR ecosystem
      </li>,
      <li>
        {" "}
        Each holder will be eligible to claim up to 10,000 $TOLR tokens on the
        first phase of the airdrop.
      </li>,
      <li>
        Furthermore the holders of the $TOLR token will be eligible to stake
        their tokens and generate staking rewards.
      </li>,
      <li>
        The $TOLR token holders will also have voting rights on the DAO
        mechanism within the TOLR ecosystem.
      </li>,
      <li>
        TOLR token will also include Delegated voting and special access arenas
        within the TOLR ecosystem.
      </li>,
    ],
  },
  {
    id: 3,
    date: "2006 - 2008",
    title: "NFT-DAO Release",
    subTitle: "Lorem ipsum",
    description: [
      <li>
        The release of the TOLR DAO will be major turning point of the TOLR
        ecosystem, giving power to the community and decide on the direction of
        the project.
      </li>,
    ],
  },
  {
    id: 4,
    date: "2006 - 2008",
    title: "T-Club Card Release",
    subTitle: "Lorem ipsum",
    description: [
      <li>
        The T-Club is an exclusive token of appreciation granting access to
        various segments within the TOLR ecosystem as well as VIP events.
      </li>,
    ],
  },
  {
    id: 5,
    date: "2006 - 2008",
    title: " TOLR Game Beta Release",
    subTitle: "Lorem ipsum",
    description: [
      <li>
        The release of the Beta version of the TOLR game encompassing the true
        utility of the TOLR NFT as well as the $TOLR token. TOLR Gaming Bounty
        Announcements
      </li>,
      <li>
        In order to promote and uplift the interactions within the TOLR
        community, a gaming bounty programme will be introduced where existing
        members as well as new members can win $TOLR or $USDT prizes or NFTs
        based on the bounty completions within this programme.
      </li>,
    ],
  },
  {
    id: 6,
    date: "2006 - 2008",
    title: "TOLR Bounty Airdrop",
    subTitle: "Lorem ipsum",
    description: [
      <li>
        Successful participants of the bounty programme tasks will be able to
        receive airdrop rewards based on their activity in the TOLR ecosystem.
      </li>,
    ],
  },
  {
    id: 7,
    date: "2006 - 2008",
    title: " TOLR Game Release",
    subTitle: "Lorem ipsum",
    description: [
      <li>
        The ultimate product of the TOLR ecosystem and the end goal of the TOLR
        Project, the TOLR game release will take forward the foundations laid
        out in the previous roadmap milestones and create a binding community
        and an entire ecosystem of new generation gamers and take the TOLR
        legacy forward
      </li>,
    ],
  },
];
