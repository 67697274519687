import { GiLoincloth } from "react-icons/gi";
import { AiFillFire } from "react-icons/ai";
import { ImScissors } from "react-icons/im";
import { BiFace } from "react-icons/bi";

export const QuestionArray = [
  {
    id: 1,
    qtitle: `What is NFT and how does it relate to "The Outlaw's Last Ride"?`,
    answer: `NFT stands for Non-Fungible Token and it is a unique digital asset that represents ownership of a specific item or piece of content, such as a collectible or in-game item. In the case of "The Outlaw's Last Ride", NFTs will be used to represent unique in-game items, such as weapons, outfits, and other collectibles.`,
    icon: <GiLoincloth size={22} />,
  },
  {
    id: 2,
    qtitle: `How can I purchase NFTs in "The Outlaw's Last Ride"?`,
    answer: `To purchase NFTs in "The Outlaw's Last Ride", you will need to have a supported cryptocurrency wallet, such as MetaMask, and access to a cryptocurrency exchange, such as Binance. From there, you can purchase the specific NFTs you are interested in through the game's marketplace or through a third-party marketplace that supports NFTs.`,
    icon: <AiFillFire size={22} />,
  },
  {
    id: 3,
    qtitle: `What makes NFTs in "The Outlaw's Last Ride" unique?`,
    answer: `NFTs in "The Outlaw's Last Ride" are unique because they are one-of-a-kind and cannot be replicated or duplicated. This means that you will own a truly unique and valuable in-game item that no one else can claim as their own.`,
    icon: <ImScissors size={22} />,
  },
  {
    id: 4,
    qtitle: `Are NFTs in "The Outlaw's Last Ride" tradable?`,
    answer: `Yes, NFTs in "The Outlaw's Last Ride" can be traded with other players through the game's marketplace or through a third-party marketplace. The value of the NFT will be determined by the market demand for the item, as well as its rarity and uniqueness.`,
    icon: <BiFace size={22} />,
  },
  {
    id: 5,
    qtitle: `Will my NFTs still be accessible if the game closes or goes offline?`,
    answer: `Yes, your NFTs are stored on the blockchain and are not tied to the game itself. As long as you have access to your cryptocurrency wallet, you will be able to access and trade your NFTs, even if the game goes offline.`,
    icon: <GiLoincloth size={22} />,
  },
  {
    id: 6,
    qtitle: `Is there a limit to the number of NFTs that can be created in "The Outlaw's Last Ride"?`,
    answer: `There may be a limited number of certain types of NFTs available in "The Outlaw's Last Ride", but the exact number will depend on the specific item or collectible. Once all of the NFTs of a certain type have been sold, they will not be available for purchase again.`,
    icon: <BiFace size={22} />,
  },
  {
    id: 7,
    qtitle: `What is the Mint Price?`,
    answer: (
      // <p>
      //   Presale Price: 25 Matic <br /> Public Sale Price: 35 Matic <br /> After
      //   Sale: 45 Matic
      // </p>
      <p>Public Sale Price: 35 Matic</p>
    ),
    icon: <BiFace size={22} />,
  },
  {
    id: 8,
    qtitle: `What are the potential benefits of investing in "The Outlaw's Last Ride" token?"?`,
    answer: `"The Outlaw's Last Ride" tokens have a fixed supply of 9.6 million, and the smart contract does not allow for minting to ensure investor safety. Additionally, "The Outlaw's Last Ride" token holders benefit from the reflection function, as it is used as a utility in the "The Outlaw's Last Ride" ecosystem.

    Investing in the "The Outlaw's Last Ride" token can offer potential benefits such as diversification of portfolio, exposure to emerging blockchain technology, and potential for financial gains.`,
    icon: <BiFace size={22} />,
  },
  {
    id: 9,
    qtitle: `How secure is "The Outlaw's Last Ride"?`,
    answer: `If we talk about the security of "The Outlaw's Last Ride", then the "The Outlaw's Last Ride" website and all the products of our ecosystem use the SHA-256 bit algorithm to secure its smart contract.

    Apart from this, the "The Outlaw's Last Ride" team has cyber security experts. If so, then you do not need to worry at all. We will never launch any product of "The Outlaw's Last Ride" without A/B testing which will be tested by our team before launching.`,
    icon: <BiFace size={22} />,
  },
];
