// import { AiFillInstagram } from "react-icons/ai";
// import { AiFillTwitterCircle } from "react-icons/ai";
// import { FaDiscord } from "react-icons/fa";
// import { HiCurrencyPound } from "react-icons/hi";

export const menus = [
  {
    id: 1,
    name: "Home",
    url: "/",
  },
  // {
  //   id: 2,
  //   name: "GALLERY",
  //   // url: "/gallery",
  // },
  // {
  //   id: 3,
  //   name: "WORLD",
  //   url: "/worldbg",
  // },
  {
    id: 4,
    name: "ROADMAP",
    url: "#roadmap",
  },
  {
    id: 5,
    name: "FAQ",
    url: "#faq",
  },
  // {
  //   id: 5,
  //   name: "MORE",
  //   //url: "/roadmapTwo",
  // },
  // {
  //   id: 6,
  //   name: "BUY",
  //   //url: "/roadmapThree",
  // },

  // {
  //   id: 11,
  //   name: "CONNECT",
  //   url: "/",
  // },
];
