import React from "react";

const QsectionFooter = () => {
  return (
    <div className="lg:mt-10 mt:6">
      <p className="lg:text-xl text-[.7rem] text-center">
        Haven’t got your answer?
        <span className="ml-2 text-[#f0c942]">
          <a href="https://twitter.com/OutlawsLastRide" target="_blank">
            Contact us on <span className="underline">Twitter</span>
          </a>
        </span>
      </p>
    </div>
  );
};

export default QsectionFooter;
